<template>
  <div class="table">
    <table>
      <tr class="nohover">
        <th colspan="4">Contacts</th>
      </tr>
      <tr v-for="contact in contacts" :key="contact.contactid">
        <td class="name-td">
          <input type="text" placeholder="name" autocomplete="chrome-off" v-model="contact.name" />
        </td>
        <td class="email-td">
          <input type="text" placeholder="email" autocomplete="chrome-off" v-model="contact.email" />
        </td>
        <td class="email-td">
          <input
            type="text"
            placeholder="phone"
            autocomplete="chrome-off"
            :value="formatedContactNumber(contact.phone)"
            @keydown="enforceFormat($event)"
            @keyup="updateContactNumber($event, contact.contactid)"
          />
        </td>
        <td class="img">
          <img
            :src="contact.isprimary ? require('../assets/star-filled.svg') : require('../assets/star.svg')"
            alt=""
            class="action-btn star"
            @click="
              () => {
                clearPrimary(contact);
                contact.isprimary = !contact.isprimary;
              }
            "
          />
          <img
            class="action-btn"
            src="../assets/trash-white.svg"
            alt=""
            @click="
              setAlert(
                'Are you sure you want to delete this contact?',
                'CANCEL',
                'DELETE',
                'deletecontact',
                contact.contactid
              )
            "
          />
        </td>
      </tr>
      <tr class="nohover add-new-contact">
        <td colspan="4">
          <img class="action-btn" src="../assets/plus-slim.svg" alt="" @click="addNewContact(clientid)" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { watchEffect } from 'vue';
import contacts from '../composables/contacts';
import global from '../composables/global';
import phone from '../composables/phone';

export default {
  props: ['clientid'],
  setup(props) {
    contacts.getContacts(props.clientid);
    watchEffect(() => {
      contacts.getContacts(props.clientid);
    });

    return {
      contacts: contacts.contacts,
      getContacts: contacts.getContacts,
      addNewContact: contacts.addNewContact,
      setAlert: global.setAlert,
      clearPrimary: contacts.clearPrimary,
      enforceFormat: phone.enforceFormat,
      updateContactNumber: phone.updateContactNumber,
      formatedContactNumber: phone.formatedContactNumber,
    };
  },
  methods: {
    formatPhone(str) {
      const cleaned = ('' + str).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  padding-top: 25px;
  max-width: 700px;
  table {
    width: 100%;
    overflow: hidden;

    border-collapse: collapse;
    tr:hover {
      background: #575757;
      cursor: default;
      input {
        background: #575757;
      }
    }
    tr.nohover:hover {
      background: #3a3a3a;
      cursor: default;
    }
    tr.add-new-contact td {
      padding-top: 10px;
    }
    th {
      text-align: left;
      font-size: 0.6em;
      border-bottom: 1px solid #dfdfdf;
      padding-left: 8px;
    }
    td {
      padding: 2px 8px;
      input {
        border-bottom: none;
        padding: 1px;
        width: 100%;
        margin-top: 3px;
      }
      &.img {
        width: 25px;
        text-align: right;
      }
      img {
        width: 18px;
      }
      img.star {
        width: 20px;
        margin-right: 10px;
      }
    }
    td.name-td {
      width: 30%;
    }
    td.email-td {
      width: 30%;
    }
    td.phone-td {
      width: calc(40% - 25px);
    }
  }
}
</style>
