import { computed, nextTick } from 'vue';
import client from './client';
import contacts from './contacts';

const isNumericInput = (event: KeyboardEvent) => {
  const key = event.keyCode;
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  );
};

const isModifierKey = (event: KeyboardEvent) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};

const enforceFormat = (event: KeyboardEvent) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

const updateClientNumber = (event: KeyboardEvent) => {
  const target = event.target as HTMLInputElement;
  const input = target.value.replace(/\D/g, '');
  client.clientDetails.value.phone = input;
};

const formatedClientNumber = computed(() => {
  const value = client.clientDetails.value.phone;
  const input = value.replace(/\D/g, '');
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${areaCode}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    return `(${areaCode}`;
  } else {
    return null;
  }
});

const updateContactNumber = (event: KeyboardEvent, contactid: string) => {
  const target = event.target as HTMLInputElement;
  const input = target.value.replace(/\D/g, '');
  contacts.contacts.value.filter((v) => v.contactid == contactid)[0].phone = input;
};

const formatedContactNumber = (value: string) => {
  const input = value.replace(/\D/g, '');
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${areaCode}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    return `(${areaCode}`;
  } else {
    return null;
  }
};

export default { enforceFormat, updateClientNumber, formatedClientNumber, updateContactNumber, formatedContactNumber };
