<template>
  <div v-if="(id && clientDetails.name) || !id" class="main-panel">
    <input type="text" class="header-text" v-model="clientDetails.name" spellcheck="false" placeholder="client name" />
    <div class="sub-panel">
      <div class="inputs">
        <input
          type="text"
          autocomplete="chrome-off"
          placeholder="address line 1"
          v-model="clientDetails.addressline1"
          class="space-above"
        />
        <input
          type="text"
          autocomplete="chrome-off"
          placeholder="address line 2"
          v-model="clientDetails.addressline2"
        />
        <input type="text" autocomplete="chrome-off" placeholder="city" v-model="clientDetails.city" />
        <input type="text" autocomplete="chrome-off" placeholder="state" v-model="clientDetails.state" />
        <input type="text" autocomplete="chrome-off" placeholder="zip" v-model="clientDetails.zip" />
        <input
          type="text"
          autocomplete="chrome-off"
          placeholder="website"
          v-model="clientDetails.website"
          class="space-above"
        />
        <input
          type="text"
          autocomplete="chrome-off"
          placeholder="phone"
          :value="formatedClientNumber"
          @keydown="enforceFormat($event)"
          @keyup="updateClientNumber($event)"
          class="space-above"
        />
      </div>
      <div class="right-panel">
        <button
          class="danger"
          @click="setAlert('Are you sure you want to delete this client?', 'CANCEL', 'DELETE', 'deleteclient')"
        >
          DELETE
        </button>
        <p v-if="errStatus" @click="saveClient">error!</p>
        <p v-else-if="saveStatus">saved</p>
        <p v-else @click="saveClient">save</p>
      </div>
    </div>
    <Contacts v-if="clientDetails.clientid" :clientid="clientDetails.clientid" />
  </div>
  <div v-else class="loading">
    <img src="../assets/loader-icon.svg" alt="" />
  </div>
</template>

<script>
import Contacts from '../components/Contacts.vue';
import client from '../composables/client';
import global from '../composables/global';
import phone from '../composables/phone';

export default {
  props: ['id'],
  components: { Contacts },
  setup(props) {
    client.setClientId(props.id || '');
    return {
      clientDetails: client.clientDetails,
      saveStatus: client.saveStatus,
      errStatus: client.errStatus,
      saveClient: client.saveClient,
      setAlert: global.setAlert,
      enforceFormat: phone.enforceFormat,
      updateClientNumber: phone.updateClientNumber,
      formatedClientNumber: phone.formatedClientNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
.sub-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 700px;
  .inputs {
    display: flex;
    flex-direction: column;
    .space-above {
      margin-top: 20px;
    }
  }
  .right-panel {
    text-align: right;
    p {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.loading {
  img {
    padding: 50px 70px;
    width: 200px;
    height: 200px;
  }
}
</style>
